<template>
  <workspace-panel :win="win" @reload="reloadPage">
    <template #title>Apertura y Cierre de mes</template>
    <template>
      <!-- tabs -->
      <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab href="#open-month">
          Apertura de operaciones de mes
          <v-icon>mdi-calendar-lock-open</v-icon>
        </v-tab>
        <v-tab href="#close-month">
          Cierre de operaciones de mes
          <v-icon>mdi-calendar-lock</v-icon>
        </v-tab>
      </v-tabs>

      <!-- tabs contents -->
      <v-tabs-items v-model="tab">
        <v-tab-item value="open-month">
          <v-card class="pa-1" flat>
            <accounting-open-month :win="win"></accounting-open-month>
          </v-card>
        </v-tab-item>
        <v-tab-item value="close-month">
          <v-card class="pa-1" flat>
            <accounting-close-month :win="win"></accounting-close-month>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </workspace-panel>
</template>

<script>
import AccountingCloseMonth from "../partial/AccountingCloseMonth.vue";
import AccountingOpenMonth from "../partial/AccountingOpenMonth.vue";

export default {
  name: "OpenCloseMonthPage",
  props: ["win"],
  components: {
    AccountingOpenMonth,
    AccountingCloseMonth,
  },
  data: () => ({
    tab: "open-month",
  }),
  methods: {
    reloadPage() {
      console.log("Reload Page");
    },
  },
};
</script>
