<template>
  <v-card color="white" rounded="lg" outlined>
    <v-card-text class="pa-2">
      <v-alert class="lighten-2" color="grey">
        <div class="text-h6">APERTURA DE OPERACIONES DE MES</div>
        <p>Este proceso abre las operaciones de un mes.</p>
        <p>
          Una vez aperturado el mes es posible crear, modificar o eliminar
          partidas.
        </p>
      </v-alert>
      <p class="text-body-1 text-center">
        Seleccione el mes que desea aperturar operaciones
      </p>

      <!-- form -->
      <v-form @submit.prevent="confirmOpen">
        <v-row justify="center">
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-select
              v-model="form.month"
              label="Mes"
              :items="months"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pb-3" justify="center">
          <v-col cols="12" sm="6" md="5" lg="3">
            <v-btn type="submit" color="save" dark block>
              <div v-if="$vuetify.breakpoint.xs" class="d-flex flex-column">
                <span>Aperturar operaciones</span><br />
                <span>del mes seleccionado</span>
              </div>
              <template v-else>
                Aperturar operaciones del mes seleccionado
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <!-- confirm dialog -->
    <alert
      v-model="dialog"
      type="confirm"
      title="Confirmar la apertura de operaciones"
      :message="`¿Confirma la apertura de operaciones para el mes ${form.month}?`"
      @action="openMonthOperations"
    ></alert>
  </v-card>
</template>

<script>
export default {
  name: "OpenMonthPage",
  data: () => ({
    dialog: false,
    form: {
      month: "Enero",
    },
  }),
  computed: {
    months() {
      const months = [];
      for (let i = 0; i < 12; i++) {
        let monthName = this.moment().month(i).format("MMMM");
        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        months.push(monthName);
      }
      return months;
    },
  },
  methods: {
    confirmOpen() {
      this.dialog = true;
    },
    openMonthOperations() {
      this.dialog = false;
      console.log("open month operations", this.form.month);
    },
  },
};
</script>
