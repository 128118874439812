<template>
  <v-card color="white" rounded="lg" outlined>
    <v-card-text class="pa-2">
      <v-alert color="warning">
        <div class="text-h6">CIERRE DE OPERACIONES DE MES</div>
        <p>Este proceso cierra las operaciones de un mes.</p>
        <p>
          Una vez cerrado ya no es posible crear, modificar o eliminar partidas.
        </p>
        <p>
          Si desea modificar un mes cerrado, vaya a la opción de
          <span class="font-weight-bold">APERTURA DE OPERACIONES DE MES</span>
          en el menú
          <span class="font-weight-bold"
            >Contabilidad &#187; Apertura y cierre de mes</span
          >
        </p>
      </v-alert>
      <p class="text-body-1 text-center">
        Seleccione el mes que desea cerrar operaciones
      </p>

      <!-- form -->
      <v-form @submit.prevent="confirmClose">
        <v-row justify="center">
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-select
              v-model="form.month"
              label="Mes"
              :items="months"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pb-3" justify="center">
          <v-col cols="12" sm="6" md="5" lg="3">
            <v-btn type="submit" color="save" dark block>
              <div v-if="$vuetify.breakpoint.xs" class="d-flex flex-column">
                <span>Cerrar operaciones</span><br />
                <span>del mes seleccionado</span>
              </div>
              <template v-else>
                Cerrar operaciones del mes seleccionado
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <!-- confirm dialog -->
    <alert
      v-model="dialog"
      type="confirm"
      title="Confirmar cierre de operaciones"
      :message="`¿Confirma el cierre de operaciones para el mes ${form.month}?`"
      @action="closeMonthOperations"
    ></alert>
  </v-card>
</template>

<script>
export default {
  name: "CloseMonthPage",
  data: () => ({
    dialog: false,
    form: {
      month: "Enero",
    },
  }),
  computed: {
    months() {
      const months = [];
      for (let i = 0; i < 12; i++) {
        let monthName = this.moment().month(i).format("MMMM");
        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        months.push(monthName);
      }
      return months;
    },
  },
  methods: {
    confirmClose() {
      this.dialog = true;
    },
    closeMonthOperations() {
      this.dialog = false;
      console.log("close month operations", this.form.month);
    },
  },
};
</script>
